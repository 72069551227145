.icAgentContainer {
  min-width: 20vw;
  max-width: 20vw;
  min-height: 20vw;
  max-height: 20vw;
  width: 100%;
  height: 100%;
  background-color: #00b3c7;
}
.icAgent {
  height: 100%;
  width: 80% !important;
  color: rgb(255, 255, 255);
}
.pulse {
  border: 0;
  border-radius: 50%;
  animation: pulse 1.5s 6;
  box-shadow: 0 0 0 0 #40c6d580;
}
@keyframes pulse {
  0% {
    transform: scale(0.8);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 30px #40c6d500;
  }
  100% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 #40c65000;
  }
}
.buttons {
  border-radius: 50%;
  padding: 1rem !important;
  transition: all 300ms ease-in-out;
}
.buttons__ic {
  height: 1rem;
  z-index: 100;
  width: 1.6rem !important;
}
.btn_end {
  background: #b91c1c;
}
.btn_active {
  background: #00b3c7;
}
.btn_inactive {
  background: #3c4655;
}
.text_primary {
  color: #00B3C7;
}

@media (max-width: 764px) {
  .icAgentContainer {
    min-width: 60vw;
    max-width: 60vw;
    min-height: 60vw;
    max-height: 60vw;
    width: 100%;
    height: 100%;
    background-color: #00b3c7;
  }

  .title {
    margin-top: 5rem;
  }
}

.name {
  position: absolute;
  z-index: 10;
  top: 8px;
  right: 0;
  margin: 0;
  padding: 0.5em 1em 0.5em 0.5em;
  color: #fff;
  font-size: 20px;
  line-height: 1;
}

video {
  object-fit: cover;
  width: 100%;
  object-position: center;
}

.participant {
  display: flex;
  align-items: center;
}

.tracks-container {
  border-radius: 25px;
}

@media screen and (orientation: portrait) {
  .local-container {
    /*width: 100%;*/
    height: 25%;
  }
}

/* For landscape, we want the tool bar stick on the left */

@media screen and (orientation: landscape) {
  .local-container {
    /* width: 2.65em; */
    height: 33.33%;
  }
}
