@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  height: 100vh;
}

html,
body {
  @apply bg-gray-800;
}
